import { useEffect, useState } from "react";
import { Pj, PrCategory } from "./dataStructure";
import "./style.css";

PrCategory.$("minecraft", "마인크래프트 개발", "img/minecraft.png")
    .Projects(
        Pj.$("Luminous RPG").Platform("MCBE").Images().Description("루미너스 RPG는 높은 퀄리티를 자랑하는 반 오픈월드 액션 RPG 서버입니다.").Link("오픈채팅", ""),
        Pj.$("Valorant Addon").Platform("MCBE"),
    )

PrCategory.$("unity", "유니티 개발", "img/unity.png")
    .Projects(
        Pj.$("범근 러쉬").Platform("PC").Description("동아리 과제로 제작한 연출 연습을 위한 프로젝트 입니다."),
        Pj.$("Eucliwood Edge").Platform("PC/Mobile").Description("")
    )

function Project() {
    const [categoryItem, SetCategoryItem] = useState<JSX.Element[]>([]);
    let category = "minecraft";

    const Update = ()=>{
        const itms = PrCategory.data.map((v)=>
            <div className="category-item pointer" id={"category-" + v.id} key={"category-" + v.id} onClick={
                ()=>{
                    category = v.id;
                    Update();
                }
            }>
                <img src={v.img}></img>
                {(category === v.id) ? <div className="category-selected"></div> : <></>}
            </div>
        );
        SetCategoryItem(itms);
    }

    useEffect(()=>{
        Update();
    }, [])
    return (
        <>
            <div className="category">
                {categoryItem}
            </div>
            <div></div>
        </>
    )
}

export default Project;